import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../firebase-config";
import { AppContext } from "../../App";
import { showToastNotification } from "../../utils/ToastNotification";
import { getAppConfig } from "../../config/appConfig";
import { postNewUser } from "../../utils/postNewUser";
import axios from "axios";

const appConfig = getAppConfig();

const useUserAuthentication = () => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { setUser, setUserLoggedin, isUserLoggedIn, token } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const showPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onLoginHandler = async (loginData) => {
    setIsLoading(true);
    try {
      const userCredentials = await signInWithEmailAndPassword(auth, loginData.email, loginData.password);
      // Successful login
      if (userCredentials.user.emailVerified) {
        // console.log("Logged in user with details: ", loginData);
        setIsLoading(false);
        // postNewUser(loginData);
        showToastNotification("Welcome back, financial trailblazer! Let's dive into the financial fray once more! 💼🌟🔓", 'success', 'login');
        navigate(location.state?.from ? location.state.from : "/");
      } else {
        setIsLoading(false);
        // console.log("Logged in user with details: ", loginData);
        showToastNotification("Welcome! It looks like your email hasn't been verified yet. Please check your inbox.", 'info', 'login');
        navigate(location.state?.from ? location.state.from : "/");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.code === 'auth/invalid-email') {
        showToastNotification('Invalid email!', 'error', 'login');
      } else if (error.code === 'auth/user-not-found') {
        showToastNotification('User not found!', 'error', 'login');
      } else if (error.code === 'auth/wrong-password') {
        showToastNotification('Wrong password!', 'error', 'login');
      } else {
        showToastNotification('Error logging in!', 'error', 'login');
      }
    }
  };

  const onSignupHandler = async (signupData) => {
    try {
      setIsLoading(true);
      const userCredential = await createUserWithEmailAndPassword(auth, signupData.email, signupData.password);
      // Successful signup
      if (userCredential) {
        await updateProfile(auth.currentUser, {
          displayName: signupData.name,
        });
        postNewUser(signupData);
        navigate(location.state?.from ? location.state.from : "/");
        showToastNotification('Welcome, new financial enthusiast! Prepare to embark on an exciting journey towards financial success! 💼🌟🚀', 'success', 'login');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.code === 'auth/invalid-email') {
        showToastNotification('Invalid email!', 'error', 'login');
      } else if (error.code === 'auth/email-already-in-use') {
        showToastNotification('Email already in use!', 'error', 'login');
      } else {
        showToastNotification('Error signing up!', 'error', 'login');
      }
    }
  };

  function postNewGoogleUser(userData) {
    axios.post(appConfig.user, {
      name: userData.displayName,
      email: userData.email,
      phone: userData.phoneNumber,
      photoUrl: userData.photoURL,
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  const onGoogleSignInHandler = async () => {
    try {
      setIsLoading(true);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      // Successful Google login
      if (result) {
        postNewGoogleUser(result.user);
        showToastNotification(`Google login successfull!`, 'success', 'login');
        // console.log("result", result);
        navigate(location.state?.from ? location.state.from : "/");
      }
      else {
        setIsLoading(false);
        showToastNotification('Error with Google login. Please Try Again!', 'error', 'login');
      }
    } catch (error) {
      setIsLoading(false);
      showToastNotification('Error with Google login. Please Try Again!', 'error', 'login');
    }
  }

  const userAuthHandler = onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
      localStorage.setItem('user', JSON.stringify({
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        emailVerified: user.emailVerified,
        photoURL: user.photoURL,
      }))
      setUserLoggedin(true);
    } else {
      setUser(null);
    }
  });

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("user");
        sessionStorage.setItem("userLoggedOut", "true");
        setUserLoggedin(false);
        showToastNotification('Goodbye, financial adventurer! See you on the next growth expedition! 🌟💼👋', 'success', 'login')
        setTimeout(() => {
          if(window.location.pathname !== '/my-profile') {
            window.location.reload();
          }else{
            navigate('/');
          }
        }, 1500);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    userAuthHandler();
    if (isUserLoggedIn && (location.pathname === '/login' || location.pathname === '/signup')) {
      showToastNotification('You are already logged in!', 'info', 'login');
      navigate(location.state?.from ? location.state.from : "/");
    }
  }, [isUserLoggedIn]);


  return {
    userAuthHandler,
    userSignOut,
    passwordVisible,
    showPassword,
    onLoginHandler,
    onSignupHandler,
    onGoogleSignInHandler,
    isLoading,
  }
};

export default useUserAuthentication;
