import Logo from "../assets/Full_Logo.svg";
import { Link } from "react-router-dom";
import home_footer_wave from "../assets/home-footer-wave.svg"
import { openAffiliateLinkIfValid } from "../utils/openAffiliateLink";
import { FOOTER_YEAR } from "../utils/Constants";
import { RefferalModal } from "./homepage/modals/ReferralModal";
import { CreditCardModal } from "./homepage/modals/CreditCardModal";

function Footer() {

    return (
        <>
            <div className="footer container mx-auto pb-5">
                <footer className="row text-center">
                    <div className="col-12 col-md-4 my-3">
                        <h3 className="lead">Get Started</h3>

                        <ul className="list-group mx-auto">
                            <Link className="link-page btn" type="button" data-bs-toggle="modal" data-bs-target="#referralModal" >
                                <li className="list-group-item px-0 mx-0">
                                    <p className="text-center link-page">Investments</p>
                                </li>
                            </Link>

                            <Link className="link-page btn" type="button" data-bs-toggle="modal" data-bs-target="#ccModal">
                                <li className="list-group-item px-0 mx-0">
                                    <p className="text-center link-page">Credit Cards</p>
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div className="col-12 col-md-4 my-3">
                        <h3 className="lead">Sitemap</h3>
                        <ul className="list-group">
                            <li className="list-group-item link-page">
                                <Link className="navbar-brand text-wrap" to="/div-cal">
                                    Dividend Calendar
                                </Link>
                            </li>
                            <li className="list-group-item link-page">
                                <Link className="navbar-brand text-wrap" to="/his-div-dat">
                                    Historic Dividend Data
                                </Link>
                            </li>
                            <li className="list-group-item link-page">
                                <Link className="navbar-brand text-wrap" onClick={() => openAffiliateLinkIfValid({}, "/no-cost-emi-calculator")} to="/no-cost-emi-calculator">
                                    Real Cost of No Cost EMI
                                </Link>
                            </li>
                            <li className="list-group-item link-page">
                                <Link className="navbar-brand text-wrap" to="/contact-us" target={"_blank"} rel="noreferrer">
                                    Contact Us
                                </Link>
                            </li>
                            <li className="list-group-item link-page">
                                <Link className="navbar-brand text-wrap" to="/faq">
                                    FAQ
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-4 my-3">
                        <h3 className="lead">About Us</h3>

                        <ul className="list-group mx-auto">
                            <a className="link-page btn" href="https://www.linkedin.com/in/theApurvaGaurav/" target={"_blank"} rel="noreferrer">
                                <li className="list-group-item px-0 mx-0">
                                    <p className="text-center link-page"><i className="fab fa-linkedin"></i> Apurva Gaurav </p>
                                </li>
                            </a>

                            <a className="link-page btn" href="https://www.linkedin.com/in/kanchanbharti/" target={"_blank"} rel="noreferrer">
                                <li className="list-group-item px-0 mx-0">
                                    <p className="text-center link-page"><i className="fab fa-linkedin"></i> Kanchan Bharti</p>
                                </li>
                            </a>
                        </ul>

                    </div>

                    <div className="row mx-auto pt-5 w-100">
                        <ul className="mx-auto px-0">
                            <li className="list-inline-item mx-auto">
                                <Link className="navbar-brand" to="/">
                                    <img width={80} src={Logo} alt="pFinTools Logo"  loading="lazy"/>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <span className="text-large mx-3">© {FOOTER_YEAR}</span>
                            </li>
                        </ul>

                        <p className="brand-icons">
                            <span>
                                <a className="link-page" target={"_blank"} rel="noreferrer" href="https://twitter.com/pfintools"><i className="fa-brands fa-twitter"></i></a>
                            </span>
                            <span>
                                <a className="link-page" target={"_blank"} rel="noreferrer" href="https://www.linkedin.com/company/pFinTools"><i className="fa-brands fa-linkedin"></i></a>
                            </span>
                            <span>
                                <a className="link-page" target={"_blank"} rel="noreferrer" href="https://www.reddit.com/r/pFinTools/"><i className="fa-brands fa-reddit"></i></a>
                            </span>
                            <span>
                                <a className="link-page" target={"_blank"} rel="noreferrer" href="https://www.instagram.com/pfintools/"><i className="fa-brands fa-instagram"></i></a>
                            </span>
                            <span>
                                <a className="link-page" target={"_blank"} rel="noreferrer" href="https://discord.gg/WaphPUVMpb"><i className="fa-brands fa-discord"></i></a>
                            </span><br /><br />
                            <div>
                                <a className="legal" href="/privacy-policy">Privacy Policy</a>
                            </div>
                            <div>
                                <a className="legal" href="/terms-and-conditions">Terms and Conditions</a>
                            </div>


                        </p>

                    </div>
                </footer >
            </div >

            <div>
                <img src={home_footer_wave} width="100%" alt="yellow wave" />
            </div>

            {/* Referral links Modal  */}
            <RefferalModal />
            {/* Credit Card Modal */}
            <CreditCardModal />

        </>

    );
}

export default Footer;