// Import all the required functions from the logic.js

import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { page_titles } from "../PageTitles";
import { rate, gst, calculateIRR } from "./Logic";
import NCETabs from "./NCETabs";

// Importing Assets
import priceOfTheItem_info from "../../assets/Info_Assets/priceOfTheItem_info.gif";
import OneTimeDiscount_info from "../../assets/Info_Assets/OneTimeDiscount_info.gif";
import NoCostEMIDiscount_info from "../../assets/Info_Assets/NoCostEMIDiscount_info.gif";
import procFees_info from "../../assets/Info_Assets/procFees_info.gif";
import tenure_info from "../../assets/Info_Assets/tenure_info.gif";
import add_to_chrome_button from "../../assets/add_to_chrome_button.png";
import { Banner } from "../banners/BannerWithButtons";
import Comments from "../comments/Comments";
import { sendErrorLog } from "../../utils/sendErrorLog";

function OldNoCostEMI() {
  // State Variables
  const [priceOfTheItem, setPrice] = useState(0);
  const [NoCostEMIDiscount, setEMIDiscount] = useState(0);
  const [oneTimeDiscount, setOneTimeDiscount] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [procFees, setProcFees] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [calculatedIRR, setcalculatedIRR] = useState(
    "<Click on Calculate and Find Out>"
  );
  const [AmountToBePaid, setAmountToBePaid] = useState(
    "<Click on Calculate and Find Out>"
  );
  const [tableShow, setTableShow] = useState(false);
  const [netGstOnInterest, setNetGstOnInterest] = useState(0);
  const [netProcFeesGst, setNetProcFeesGst] = useState(0);
  const [netPrincipalRepaid, setNetPrincipalRepaid] = useState(0);
  const [netInterestPaid, setNetInterestPaid] = useState(0);
  const [hiddenCharges, setHiddenCharges] = useState(0);

  // State variables to show users, to avoid dynamic change:
  const [priceOfTheItem_User, setPriceOfTheItem_User] = useState(0);
  const [oneTimeDiscount_User, setOneTimeDiscount_User] = useState(0);
  const [EMI_User, setEMI_User] = useState(0);
  const [tenure_User, setTenure_User] = useState(0);

  // Normal Variables
  const EMI = priceOfTheItem / tenure;

  useEffect(() => {
    window.scrollTo(0, 0);
    var hashParams = window.location.hash.substring(1).split("&");
    if (hashParams && hashParams[0].includes("price=")) {
      for (var i = 0; i < hashParams.length; i++) {
        var p = hashParams[i].split("=");
        if (p[0]) {
          const paramName = p[0];
          const paramValue = decodeURIComponent(p[1]);
          document.getElementById(paramName).value = paramValue;
          switch (paramName) {
            case "price":
              setPrice(paramValue);
              break;
            case "emi-discount":
              setEMIDiscount(paramValue);
              break;
            case "one-time-discount":
              setOneTimeDiscount(paramValue);
              break;
            case "tenure":
              setTenure(paramValue);
              break;
            case "proc-fees":
              setProcFees(paramValue);
              break;
            default:
              break;
          }
        }
      }

      if (hashParams[0])
        setTimeout(() => {
          document.getElementById("nce-form-submit")?.click();
        }, 500);
    }
  }, []);

  // Function getting clicked when calculate button is clicked
  
  const preSubmit = () => {
    sanitizeInputs();
  }

  const handleCalculateBtnClick = (e) => {
    e.preventDefault();

    // Initialize all req variables
    const initial_loan_amount = priceOfTheItem - NoCostEMIDiscount;
    var current_loan_amount = initial_loan_amount;
    const newTableData = [];
    const cashFlow = [];
    var total_amount_to_paid = 0;
    let netGstOnInterestLocal = 0;
    let netProcFeesGstLocal = 0;
    let netInterestPaidLocal = 0;
    let netPrincipalRepaidLocal = 0;

    // GST from logic.js
    const GST = gst;

    // Find Rate of Interest
    const rate_of_interest = rate(tenure, -EMI, initial_loan_amount);

    //Adding price to cash flow
    cashFlow.push(priceOfTheItem - oneTimeDiscount);

    // Populate the tableData to be displayed in the table
    for (let month = 1; month <= tenure; month++) {
      const interest_paid = current_loan_amount * (rate_of_interest / 1200);
      const principle_repaid = EMI - interest_paid;
      const gst_on_interest = interest_paid * gst;
      var total_monthly_payment = EMI + gst_on_interest;
      var rowProcFeesGst = 0;

      if (month === 1) {
        rowProcFeesGst = procFees * (1 + gst);
      }

      total_monthly_payment += rowProcFeesGst;
      cashFlow.push(-total_monthly_payment.toFixed(2));

      let newObj = {
        month: month,
        current_loan_amount: current_loan_amount.toFixed(2),
        principle_repaid: principle_repaid.toFixed(2),
        interest_paid: interest_paid.toFixed(2),
        gst_on_interest: gst_on_interest.toFixed(2),
        proc_fees:
          month === 1
            ? (procFees * (1 + GST)).toFixed(2)
            : Number(0).toFixed(2),
        total_monthly_payment: total_monthly_payment.toFixed(2),
      };

      newTableData.push(newObj);
      netInterestPaidLocal += interest_paid;
      netPrincipalRepaidLocal += principle_repaid;
      netGstOnInterestLocal += gst_on_interest;
      netProcFeesGstLocal += rowProcFeesGst;
      total_amount_to_paid += total_monthly_payment;
      current_loan_amount -= principle_repaid;
    }

    //Updating the tableData:

    setNetInterestPaid(netInterestPaidLocal.toFixed(2));
    setNetPrincipalRepaid(netPrincipalRepaidLocal.toFixed(2));
    setNetGstOnInterest(netGstOnInterestLocal.toFixed(2));
    setNetProcFeesGst(netProcFeesGstLocal.toFixed(2));

    setPriceOfTheItem_User(priceOfTheItem);
    setOneTimeDiscount_User(oneTimeDiscount);
    setEMI_User(EMI);
    setTenure_User(tenure);

    setAmountToBePaid(total_amount_to_paid);
    let tempIRR = calculateIRR(cashFlow, 0.1) * 12;
    setcalculatedIRR(tempIRR);
    setTableData(newTableData);

    if (isNaN(tempIRR) || tempIRR === Infinity || tempIRR === -Infinity) {
      alert("Invalid data, enter valid set of numbers.");
      sendErrorLog({
        errorContent: `Invalid data entered. Price: ${priceOfTheItem}, No Cost EMI Discount: ${NoCostEMIDiscount}, One Time Discount: ${oneTimeDiscount}, Tenure: ${tenure}, Processing Fees: ${procFees}`,
        pageId: "OldNoCostEMI",
        remarks: "Invalid data entered",
      });
      setTableShow(false);
    } else {
      setTableShow(true);
    }
  };

  function sanitizeInputs() {
    const sanitizedPrice = priceOfTheItem || 0;
    const sanitizedOneTimeDiscount = oneTimeDiscount || 0;
    const sanitizedTenure = tenure || 0;
    const sanitizedProcFees = procFees || 0;
    const sanitizedNoCostEmiDiscount = NoCostEMIDiscount || 0;

    setPrice(sanitizedPrice);
    setOneTimeDiscount(sanitizedOneTimeDiscount);
    setTenure(sanitizedTenure);
    setProcFees(sanitizedProcFees);
    setEMIDiscount(sanitizedNoCostEmiDiscount);
  }

  useEffect(() => {
    setHiddenCharges(Number(netGstOnInterest) + Number(netProcFeesGst));
  }, [netGstOnInterest, netProcFeesGst]);

  return (
    <>
      <Helmet>
        <title>{page_titles["no-cost-emi"]}</title>
      </Helmet>

      <div className="container py-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link className="breadcrumbs-link" to="/">
                <i className="fa fa-home fa-xs"></i>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Real Cost of No Cost EMI
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-12 col-md-10">
            <h1 className="display-5 my-3 text-dark-green">
              Real Cost of No Cost EMI
              <a href="https://chrome.google.com/webstore/detail/credit-card-emino-cost-em/jnapejkpdpoacdgankigcbaekfgpkfcb" target="_blank" rel="noreferer">
                <img
                  className="float-end d-none d-lg-block"
                  src={add_to_chrome_button}
                  alt="Add to Chrome"
                  height="57.6px"
                  style={{ borderRadius: "5px" }}
                />
              </a>
            </h1>
            <p className="text-left pb-5">
              <br />
              Welcome to India's first and only No Cost EMI - Cost Calculator!
              Here you can calculate the actual cost of the so-called “No Cost
              EMI” offers that are ever so popular nowadays. Have fun
              experimenting with different values but be ready for some
              mind-blowing discoveries!
              <br /> <br />
              Note: We have a newer advanced version of EMI Cost Calculator
              which supports all types of transactions, and you can try it out{" "}
              <Link className="link-page" to="/no-cost-emi-calculator">
                here
              </Link>
              !
            </p>

            <div className="tabs">
              <NCETabs tab="old" />
            </div>

            <div className="filters">
              <div className="p-3 pb-1">
                <form onSubmit={(e) => handleCalculateBtnClick(e)}>
                  <div className="d-flex flex-wrap">
                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">
                        Price &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#priceModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        className="form-control-sm"
                        step="0.01"
                        type="number"
                        min="1"
                        max="10000000"
                        name="Price"
                        id="price"
                        value={priceOfTheItem}
                        onChange={(e) => {
                          setPrice(e.target.value);
                        }}
                      />
                    </fieldset>

                    <fieldset className="border-filter">
                      <legend className="float-none w-auto">
                        No Cost EMI Discount &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#NoCostEMIDiscountModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        type="number"
                        className="form-control-sm"
                        step="0.01"
                        name="EMIDiscount"
                        min={0}
                        max="10000000"
                        id="emi-discount"
                        onChange={(e) => {
                          setEMIDiscount(e.target.value);
                        }}
                        value={NoCostEMIDiscount}
                      />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">
                        Upfront Only Payment Discount &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#oneTimeDiscountModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        className="form-control-sm"
                        type="number"
                        name="OneTimeDiscount"
                        min={0}
                        max="10000000"
                        step="0.01"
                        id="one-time-discount"
                        onChange={(e) => {
                          setOneTimeDiscount(e.target.value);
                        }}
                        value={oneTimeDiscount}
                      />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">
                        Tenure in Months &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#tenureModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        className="form-control-sm"
                        type="number"
                        name="Tenure"
                        id="tenure"
                        min={1}
                        max="1200"
                        onChange={(e) => {
                          setTenure(e.target.value);
                        }}
                        value={tenure}
                      />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">
                        Processing Fees &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#processingFeesModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        className="form-control-sm"
                        type="number"
                        id="proc-fees"
                        min={0}
                        max="10000000"
                        step="0.01"
                        name="ProcFees"
                        onChange={(e) => {
                          setProcFees(e.target.value);
                        }}
                        value={procFees}
                      />
                    </fieldset>
                  </div>

                  <p className="text-center">
                    <button
                      className="btn btn-submit mx-auto m-3"
                      onClick={preSubmit}
                    >
                      Calculate
                    </button>
                    <button style={{
                      display: "none"
                    }}
                      type="submit"
                      id="nce-form-submit"
                    ></button>
                  </p>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-1"></div>
        </div>

        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-12 col-md-10">
            {tableShow ? (
              <>
                <div className="row mt-3">
                  <p className="">
                    Considering you could have bought the item at
                    <span className="text-pastel-green font-size-larger">
                      {" "}
                      Rs {priceOfTheItem_User} - Rs {oneTimeDiscount_User} = Rs{" "}
                      {priceOfTheItem_User - oneTimeDiscount_User}
                    </span>
                    , you are actually getting the No Cost EMI loan at &nbsp;
                    <b className="text-yellow font-size-xlarge">
                      {(calculatedIRR * 100).toFixed(2)}% per annum
                    </b>
                    . Even though your EMI is supposed to be{" "}
                    <span className="text-pastel-green font-size-larger">
                      {" "}
                      Rs {EMI_User.toFixed(2)}
                    </span>
                    , you will actually be paying{" "}
                    <span className="text-pastel-green font-size-larger">
                      {" "}
                      Rs {AmountToBePaid.toFixed(2)}
                    </span>{" "}
                    in total over a period of{" "}
                    <span className="text-pastel-green font-size-larger">
                      {tenure_User} months
                    </span>
                    .
                    <br />
                    <br />
                    Here's the break up of your payments overtime :-
                  </p>
                </div>
              </>
            ) : (
              <div></div>
            )}
          </div>

          <div className="col-md-1"></div>
        </div>

        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-12 col-md-10">
            {tableShow ? (
              <>
                <div className="amortisation_table mt-3">
                  {/* We need month, current loan amount, principle repiad, gst on interest */}
                  <table className="table sortable">
                    <thead>
                      <tr>
                        <th key="month">Month</th>
                        <th key="beginning_bal">Remaining Balance</th>
                        <th key="principle_repaid">Principle Repaid</th>
                        <th key="interest_paid">Interest Paid</th>
                        <th key="gst_on_interest">GST on Interest</th>
                        <th key="proc_fees">Processing Fees + GST</th>
                        <th key="total_monthly_payment">
                          Total Monthly Payment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item) => {
                        return (
                          <tr key={item.month}>
                            <td>{item.month}</td>
                            <td>{item.current_loan_amount}</td>
                            <td>{item.principle_repaid}</td>
                            <td>{item.interest_paid}</td>
                            <td>{item.gst_on_interest}</td>
                            <td>{item.proc_fees}</td>
                            <td>{item.total_monthly_payment}</td>
                          </tr>
                        );
                      })}
                      <tr id="total-row">
                        <td></td>
                        <td>Total</td>
                        <td>{netPrincipalRepaid}</td>
                        <td>{netInterestPaid}</td>
                        <td>{netGstOnInterest}</td>
                        <td>{netProcFeesGst}</td>
                        <td>{AmountToBePaid.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row mt-3">
                  <p className="text-yellow">
                    Total hidden charges: Rs.{netGstOnInterest} + Rs.{" "}
                    {netProcFeesGst} ={" "}
                    <span className="font-size-larger font-weight-bold">
                      Rs. {hiddenCharges}
                    </span>{" "}
                    (
                    {(
                      (Number(hiddenCharges) / Number(priceOfTheItem)) *
                      100
                    ).toFixed(2)}
                    % of the Price)
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}

          </div>
          <div className="col-md-1"></div>
        </div>

        {/* Related Links and Disclaimer */}
        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-12 col-md-10">
            <div className="row mt-3">
              <p className="font-weight-light">
                In case of any query/feedback, please reach out to us using the{" "}
                <Link
                  to="/contact-us"
                  className="link-page"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Contact Us
                </Link>{" "}
                section.
              </p>
            </div>

            <div className="row my-4">
              <h5>Related Links:</h5>
              <ul className="list-group">
                <li className="list-group-item bg-transparent">
                  <a className="link-page" href="https://pFinTools.com/faq">
                    Frequently Asked Questions
                  </a>
                </li>
                <li className="list-group-item bg-transparent">
                  <a
                    className="link-page"
                    href="http://pFinTools.com/faq#real-cost-of-no-cost-emi-what-are-the-hidden-costs-in-an-emi-plan-are-there-any-costs-in-no-cost-emis-as-well"
                  >
                    What Are The Hidden Costs In An EMI Plan?
                  </a>
                </li>
                <li className="list-group-item bg-transparent">
                  <a
                    className="link-page"
                    href="http://pFinTools.com/faq#real-cost-of-no-cost-emi-what-is-a-no-cost-emi-is-it-really-no-cost"
                  >
                    What Is No-Cost EMI And How Does It Work?
                  </a>
                </li>
              </ul>
            </div>

            <div className="row">
              <small className="mb-3 desclaimer">
                <strong>Disclaimer:</strong>
                <i>
                  <br />
                  This tool works only for Indian Credit Card No Cost EMI loans
                  considering GST rate of 18%. Please do not use this for other
                  types of loan calculations.
                </i>{" "}
                <br />
              </small>
            </div>

            <div>
              <Comments articleId={"old_no_cost_emi"} />
            </div>

            {/* Amazon Banner */}
            <div className="my-4">
              <Banner />
            </div>
          </div>

          <div className="col-md-1"></div>
        </div>
      </div>

      {/* MODALS FOR INFO BUTTONS ON THE LABELS OF INPUT TAGS */}

      {/* FOR PRICE INFO */}
      <div
        className="modal fade"
        id="priceModal"
        tabIndex="-1"
        aria-labelledby="priceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="priceModalLabel">
                Price
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body row mx-auto">
              <p>
                Listed(Selling) Price of the item. If there's any upfront
                discount on EMI transactions, deduct that from the price as well
                and then enter the figure in the text box.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={priceOfTheItem_info}
                  alt="Example of where to find Price"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* NO COST EMI DISCOUNT */}
      <div
        className="modal fade"
        id="NoCostEMIDiscountModal"
        tabIndex="-1"
        aria-labelledby="NoCostEMIDiscountModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="NoCostEMIDiscountModalLabel">
                No Cost EMI Discount
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body row mx-auto">
              <p>
                The interest amount that is being given as a discount to you to
                facilitate the "no-cost" EMI. (This is essentially the interest
                that was supposed to be charged but is being passed on to you as
                an upfront discount)
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={NoCostEMIDiscount_info}
                  alt="Example of where to find No Cost EMI Discount"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ONE TIME DISCOUNT */}
      <div
        className="modal fade"
        id="oneTimeDiscountModal"
        tabIndex="-1"
        aria-labelledby="oneTimeDiscountModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="oneTimeDiscountModalLabel">
                Upfront Only Payment Discount
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body row mx-auto">
              <p>
                Any payment method related discount or cashback that is only
                applicable if you make an upfront payment. In case any discount
                amount is applicable in both upfront payment as well as NCE
                option, then subtract that amount from the Price in the first
                text box and don't account for that in this text box.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={OneTimeDiscount_info}
                  alt="Example of where to find One Time Discount"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TENURE */}
      <div
        className="modal fade"
        id="tenureModal"
        tabIndex="-1"
        aria-labelledby="tenureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="tenureModalLabel">
                Tenure
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body row mx-auto">
              <p>
                No. of months for which you are opting for the no cost emi
                option.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img src={tenure_info} alt="Example of where to find Tenure" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PROCESSING FEES */}
      <div
        className="modal fade"
        id="processingFeesModal"
        tabIndex="-1"
        aria-labelledby="processingFeesModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="processingFeesModalLabel">
                Processing Fees
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body row mx-auto">
              <p>
                Processing Fees being charged from your card to facilitate the
                NCE transaction.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={procFees_info}
                  alt="Example of where to find Processing Fees"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OldNoCostEMI;
