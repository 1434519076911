import React from 'react';
import { ModalContainer, ModalContent, ModalOverlay } from './ConfirmModal';
import styled from 'styled-components';
import { PrimaryButton } from './PrimaryButton';
import { CancelButton } from './CancelButton';

// This Modal is for - first option primary, then all without borders
export const OptionSelectorModal = ({ options, onSelect, question }) => {
    return (
        <CustomModalOverlay>
            <ModalContainer>
                <ModalContent dangerouslySetInnerHTML={{ __html: question }}></ModalContent>
                <OptionsContainer>
                    {options.map((option, index) => (
                        <>
                            {index === 0 ? (
                                <CustomPrimaryButton key={index} onClick={() => onSelect(option)}>
                                    {option}
                                </CustomPrimaryButton>
                            )
                                :
                                <>
                                    or
                                    <SecondaryButton key={index} onClick={() => onSelect(option)}>
                                        {option}
                                    </SecondaryButton>
                                </>
                            }
                        </>
                    ))}
                </OptionsContainer>
            </ModalContainer>
        </CustomModalOverlay>
    );
};

const CustomModalOverlay = styled(ModalOverlay)`
    flex-direction: column;
    color: black;
    padding: 0 20px;
`

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
`

const CustomPrimaryButton = styled(PrimaryButton)`
    box-shadow: 5px 5px 20px grey;
`

const SecondaryButton = styled(PrimaryButton)`
    scale: 0.95;
`