// Import all the required functions from the logic.js

import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { page_titles } from "../PageTitles";
import Comments from "../comments/Comments";
import {
  rate,
  gst,
  calculateIRR,
  getEMIAmount,
} from "./Logic";

// Importing Assets
import NCETabs from "./NCETabs";
import add_to_chrome_button from "../../assets/add_to_chrome_button.png";
import { Banner } from "../banners/BannerWithButtons";
import { openAffiliateLinkIfValid } from "../../utils/openAffiliateLink";
import { setAmazonAffOpenedCookie } from "../../utils/setAffOpenedCookie";
import { sendErrorLog } from "../../utils/sendErrorLog";
import { OptionSelectorModal } from "../common-components/common-styled-components/OptionSelectorModal";

import priceOfTheItem_info from "../../assets/Info_Assets/priceOfTheItem_info.gif";
import OneTimePaymentDiscount_info from "../../assets/Info_Assets/NonEmiPaymentDiscount.png";
import EMI_info from "../../assets/Info_Assets/EMI.png";
import CommonDiscount_info from "../../assets/Info_Assets/CommonDiscount.png";
import CouponDiscount_info from "../../assets/Info_Assets/CouponDiscount.png";
import EmiPaymentDiscount_info from "../../assets/Info_Assets/EmiPaymentDiscount.png";
import NoCostEMIDiscount_info from "../../assets/Info_Assets/NoCostEMIDiscount_info.gif";
import procFees_info from "../../assets/Info_Assets/procFees_info.gif";
import tenure_info from "../../assets/Info_Assets/tenure_info.gif";

function NewNoCostEMI() {
  // State Variables
  const [priceOfTheItem, setPrice] = useState(0); //Ask
  const [discounts, setDiscounts] = useState({
    emiPayment: 0,
    noCostEmi: 0,
    oneTime: 0,
    common: 0,
    coupon: 0
  });
  const [tenure, setTenure] = useState(0); //Ask
  const [procFees, setProcFees] = useState(0); //Ask
  const [EMI, setEMI] = useState(0); //Ask
  const [tableData, setTableData] = useState([]);
  const [calculatedIRR, setcalculatedIRR] = useState(0);
  const [AmountToBePaid, setAmountToBePaid] = useState(0);
  const [tableShow, setTableShow] = useState(false);
  const [otpPrice, setOtpPrice] = useState(0);
  const [netGstOnInterest, setNetGstOnInterest] = useState(0);
  const [netProcFeesGst, setNetProcFeesGst] = useState(0);
  const [netPrincipalRepaid, setNetPrincipalRepaid] = useState(0);
  const [netInterestPaid, setNetInterestPaid] = useState(0);
  const [hiddenCharges, setHiddenCharges] = useState(0);
  const [actualEmi, setActualEmi] = useState(0);
  const [invalidDataHandlerState, setInvalidDataHandlerState] = useState({
    showAlert: false,
    askConfirmation: false,
    userSelected: false,
  });

  const updateDiscount = (type, value) => {
    setDiscounts((prev) => ({ ...prev, [type]: value }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var hashParams = window.location.hash.substring(1).split("&");
    let isAmazon = false;
    let ispFinTools = false;

    if (hashParams && hashParams[0].includes("price="))
      for (var i = 0; i < hashParams.length; i++) {
        var p = hashParams[i].split("=");
        if (p[0]) {
          const [paramName, paramValue] = hashParams[i].split("=").map(decodeURIComponent);
          const fieldElement = document.getElementById(paramName)
          if (fieldElement) fieldElement.value = paramValue;

          switch (paramName) {
            case "price":
              setPrice(Number(paramValue));
              break;
            case "emi-payment-discount":
              updateDiscount("emiPayment", Number(paramValue));
              break;
            case "one-time-discount":
              updateDiscount("oneTime", Number(paramValue));
              break;
            case "tenure":
              setTenure(Number(paramValue));
              break;
            case "proc-fees":
              setProcFees(Number(paramValue));
              break;
            case "no-cost-emi-discount":
              updateDiscount("noCostEmi", Number(paramValue));
              break;
            case "emi":
              setEMI(Number(paramValue));
              break;
            case "coupon-discount":
              updateDiscount("coupon", Number(paramValue));
              break;
            case "common-discount":
              updateDiscount("common", Number(paramValue));
              break;
            case "source":
              sessionStorage.setItem("nceCalcSource", paramValue);
              if (paramValue === "amazon") isAmazon = true;
              if (paramValue === "pFinTools") ispFinTools = true;
              break;
            default:
              break;
          }
        }
      }

    if (isAmazon) {
      setTimeout(() => {
        // Automatically click calculate on the first load for Amazon and set the amazon cookie
        document.getElementById("nce-form-submit")?.click();
        setAmazonAffOpenedCookie(true); // Set cookie for Amazon.
      }, 500);
    } else if (ispFinTools) {
      setTimeout(() => {
        // Automatically click calculate for pFinTools.
        document.getElementById("nce-form-submit")?.click();
      }, 500);
    }
  }, []);

  // Function getting clicked when calculate button is clicked

  const preSubmit = () => {
    sanitizeInputs();
    handleCalculateBtnClick(undefined, true)
  }

  const handleCalculateBtnClick = (e, manuallyCalculated = false) => {
    if (e) e.preventDefault();

    const urlVariables = {
      "price": priceOfTheItem,
      "emi-payment-discount": discounts.emiPayment,
      "one-time-discount": discounts.oneTime,
      "tenure": tenure,
      "proc-fees": procFees,
      "no-cost-emi-discount": discounts.noCostEmi,
      "emi": EMI,
      "coupon-discount": discounts.coupon,
      "common-discount": discounts.common,
      "source": "pFinTools"
    };

    const otpPrice =
      priceOfTheItem - discounts.oneTime - discounts.common - discounts.coupon;
    const emiPrice =
      priceOfTheItem -
      discounts.emiPayment -
      discounts.common -
      discounts.noCostEmi -
      discounts.coupon;
    let netGstOnInterestLocal = 0;
    let netProcFeesGstLocal = 0;
    let netInterestPaidLocal = 0;
    let netPrincipalRepaidLocal = 0;

    setOtpPrice(otpPrice);

    var actualEmiLocal = 0;
    var interestRate = 0;

    if (discounts.noCostEmi > 0) {
      // NCE Calculation of Interest
      actualEmiLocal = (emiPrice + discounts.noCostEmi) / tenure;
      interestRate = rate(
        Number(tenure),
        -Number(EMI),
        Number(priceOfTheItem - discounts.noCostEmi),
        0,
        0,
        20
      );
    } else {
      // Standard EMI plan, calculation of interest
      interestRate = rate(
        Number(tenure),
        -Number(EMI),
        Number(priceOfTheItem),
        0,
        0,
        0.1
      );
      actualEmiLocal = getEMIAmount(interestRate, tenure, emiPrice);
    }

    setActualEmi(actualEmiLocal)

    const cashFlow = [];
    cashFlow.push(otpPrice);

    var loanAmount = emiPrice;
    const newTableData = [];
    var total_amount_to_paid = 0;

    // Populate the tableData to be displayed in the table
    for (let month = 1; month <= tenure; month++) {
      const interest_paid = loanAmount * (interestRate / 1200);
      const principle_repaid = actualEmiLocal - interest_paid;
      const gst_on_interest = interest_paid * gst;
      var total_monthly_payment = Number(actualEmiLocal) + gst_on_interest;
      var rowProcFeesGst = 0;

      if (month === 1) {
        rowProcFeesGst = procFees * (1 + gst);
      }
      total_monthly_payment += rowProcFeesGst;
      cashFlow.push(-total_monthly_payment.toFixed(2));

      let newObj = {
        month: month,
        current_loan_amount: loanAmount.toFixed(2),
        principle_repaid: principle_repaid.toFixed(2),
        interest_paid: interest_paid.toFixed(2),
        gst_on_interest: gst_on_interest.toFixed(2),
        proc_fees:
          month === 1
            ? (procFees * (1 + gst)).toFixed(2)
            : Number(0).toFixed(2),
        total_monthly_payment: total_monthly_payment.toFixed(2),
      };

      newTableData.push(newObj);

      netInterestPaidLocal += interest_paid;
      netPrincipalRepaidLocal += principle_repaid;
      netGstOnInterestLocal += gst_on_interest;
      netProcFeesGstLocal += rowProcFeesGst;
      total_amount_to_paid += total_monthly_payment;
      loanAmount -= principle_repaid;
    }

    // Updating the tableData:

    setNetInterestPaid(netInterestPaidLocal.toFixed(2));
    setNetPrincipalRepaid(netPrincipalRepaidLocal.toFixed(2));
    setNetGstOnInterest(netGstOnInterestLocal.toFixed(2));
    setNetProcFeesGst(netProcFeesGstLocal.toFixed(2));
    setAmountToBePaid(Number(total_amount_to_paid));
    let tempIRR = calculateIRR(cashFlow, 0.1) * 12;
    setcalculatedIRR(tempIRR);
    setTableData(newTableData);

    let isInvalidData = isNaN(tempIRR) || tempIRR === Infinity || tempIRR === -Infinity;
    let isNCE = discounts.noCostEmi > 0;
    let suggestedEMI = priceOfTheItem / tenure;
    let isOutsideRange = EMI < 0.99 * suggestedEMI || EMI > 1.01 * suggestedEMI;

    if (isNCE && manuallyCalculated) {
      if (isOutsideRange) {
        setInvalidDataHandlerState((prevState) => ({
          ...prevState,
          showAlert: false,
          askConfirmation: true,
        }));
        // When the popup is shown to user, send an error log
        const uniqueID = `emi_id_${Date.now()}`;
        localStorage.setItem("emi_options_id", uniqueID);
        sendErrorLog({
          errorContent: `EMI entered is not in the range of 1% of the suggested EMI. Suggested EMI: ${suggestedEMI}, Entered EMI: ${EMI}, No Cost EMI Discount: ${discounts.noCostEmi}, Price: ${priceOfTheItem}, Tenure: ${tenure}, Processing Fees: ${procFees}, EMI Payment Discount: ${discounts.emiPayment}, Common Discount: ${discounts.common}, Coupon Discount: ${discounts.coupon}`,
          pageId: "NewNoCostEMI",
          remarks: "EMI Options shown to user, unique ID: " + uniqueID,
        });
        return;
      }
    }

    if (isInvalidData) {
      setInvalidDataHandlerState((prevState) => ({
        ...prevState,
        showAlert: true,
        askConfirmation: false,
      }));
      setTableShow(false);
      sendErrorLog({
        errorContent: `Invalid data entered. Price: ${priceOfTheItem}, EMI: ${EMI}, Tenure: ${tenure}, Processing Fees: ${procFees}, No Cost EMI Discount: ${discounts.noCostEmi}, EMI Payment Discount: ${discounts.emiPayment}, Common Discount: ${discounts.common}, Coupon Discount: ${discounts.coupon}`,
        pageId: "NewNoCostEMI",
        remarks: "Invalid data entered",
      });
    } else {
      if (manuallyCalculated) {
        openAffiliateLinkIfValid(urlVariables);
      }
      setTableShow(true);
    }
  };

  useEffect(() => {
    if (invalidDataHandlerState.showAlert) {
      alert(
        "Invalid data, enter valid set of numbers.\n\nHint 1: Make sure you enter the No Cost EMI Discount in the designated text box in case of No Cost EMIs. For standard EMIs make sure to put 0 as No Cost EMI Discount.\n\nHint 2: In the EMI field, please enter the EMI shown on product page and not on the payment page.\n\nHint 3: Refer to the info button next to the textboxes for more details."
      );
      setInvalidDataHandlerState((prevState) => ({
        ...prevState,
        showAlert: false,
        askConfirmation: false,
      }));
    }
  }, [invalidDataHandlerState.showAlert]);

  useEffect(() => {
    if (invalidDataHandlerState.userSelected) {
      handleCalculateBtnClick();
      setInvalidDataHandlerState((prevState) => ({
        ...prevState,
        userSelected: false,
        askConfirmation: false,
      }));
    }
  }, [invalidDataHandlerState.userSelected]);


  function sanitizeInputs() {
    const sanitizedPrice = priceOfTheItem || 0;
    const sanitizedEmiPaymentDiscount = discounts.emiPayment || 0;
    const sanitizedOneTimeDiscount = discounts.oneTime || 0;
    const sanitizedTenure = tenure || 0;
    const sanitizedProcFees = procFees || 0;
    const sanitizedNoCostEmiDiscount = discounts.noCostEmi || 0;
    const sanitizedEMI = EMI || 0;
    const sanitizedCouponDiscount = discounts.coupon || 0;
    const sanitizedCommonDiscount = discounts.common || 0;

    setPrice(sanitizedPrice);
    updateDiscount("emiPayment", sanitizedEmiPaymentDiscount);
    updateDiscount("oneTime", sanitizedOneTimeDiscount);
    updateDiscount("noCostEmi", sanitizedNoCostEmiDiscount);
    updateDiscount("common", sanitizedCommonDiscount);
    updateDiscount("coupon", sanitizedCouponDiscount);
    setTenure(sanitizedTenure);
    setProcFees(sanitizedProcFees);
    setEMI(sanitizedEMI);
  }

  useEffect(() => {
    setHiddenCharges(Number(netGstOnInterest) + Number(netProcFeesGst));
  }, [netGstOnInterest, netProcFeesGst]);

  return (
    <>
      <Helmet>
        <title>{page_titles["no-cost-emi"]}</title>
      </Helmet>

      {invalidDataHandlerState.askConfirmation && (
        <OptionSelectorModal
          options={[
            priceOfTheItem / tenure,
            EMI,
          ]}
          onSelect={(option) => {
            // If user has selected suggested option
            let uniqueID = localStorage.getItem("emi_options_id");
            localStorage.removeItem("emi_options_id");
            sendErrorLog({
              errorContent: `User selected EMI: ${option}, between suggested - ${priceOfTheItem / tenure} and entered - ${EMI}`,
              pageId: "NewNoCostEMI",
              remarks: `${option !== EMI ? 'User selected suggested EMI' : 'User selected same EMI'}, unique ID: ` + uniqueID,
            });

            setEMI(option);
            setInvalidDataHandlerState((prevState) => ({
              ...prevState,
              userSelected: true,
            }));
          }}
          question="Please confirm if the <b>EMI as mentioned on the product page </b>is"
        />
      )}

      <div className="container py-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link className="breadcrumbs-link" to="/">
                <i className="fa fa-home fa-xs"></i>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Real Cost of No Cost EMI
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-12 col-md-10 ">
            <h1 className="display-5 my-3 text-dark-green">
              Real Cost of No Cost EMI{" "}
              <a href="https://chrome.google.com/webstore/detail/credit-card-emino-cost-em/jnapejkpdpoacdgankigcbaekfgpkfcb" target="_blank" rel="noreferer">
                <img
                  className="float-end d-none d-lg-block"
                  src={add_to_chrome_button}
                  alt="Add to Chrome"
                  height="57.6px"
                  style={{ borderRadius: "5px" }}
                />
              </a>
            </h1>
            <p className="text-left pb-3">
              <br />
              Welcome to India's first and only No Cost EMI - Cost Calculator!
              Here you can calculate the actual cost of the so-called “No Cost
              EMI” offers, as well as standard EMI plans on your credit card.
              Keep tabs on GST, processing fees and other hidden charges to
              understand the real cost of EMIs on credit cards. You can use this
              tool to determine the best credit card to purchase a product and
              whether you should opt for EMI or non-EMI transaction.
              <br /> <br />
              Have fun experimenting but be ready for some mind-blowing
              discoveries!
            </p>

            <div className="tabs">
              <NCETabs tab="new" />
            </div>

            <div className="filters">
              <div className="p-3 pb-1">
                <form onSubmit={(e) => handleCalculateBtnClick(e, true)}>
                  <div className="d-flex flex-wrap">
                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">
                        Price &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#priceModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        className="form-control-sm"
                        step="0.01"
                        type="number"
                        min="1"
                        max="10000000"
                        name="Price"
                        id="price"
                        onChange={(e) => {
                          const value = e.target.value;
                          setPrice(value === "" ? "" : Number(value));
                        }}
                        value={priceOfTheItem}
                      />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">
                        Coupon Discount &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#couponDiscountModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        className="form-control-sm"
                        step="0.01"
                        type="number"
                        min="0"
                        max="10000000"
                        name="CouponDiscount"
                        id="coupon-discount"
                        defaultValue={0}
                        onChange={(e) => {
                          const value = e.target.value;
                          updateDiscount("coupon", value === "" ? "" : Number(value));
                        }}
                        value={discounts.coupon}
                      />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">
                        Common Discount &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#commonDiscountModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        className="form-control-sm"
                        type="number"
                        name="CommonDiscount"
                        id="common-discount"
                        min={0}
                        max="10000000"
                        defaultValue={0}
                        onChange={(e) => {
                          const value = e.target.value;
                          updateDiscount("common", value === "" ? "" : Number(value));
                        }}
                        value={discounts.common}
                      />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">
                        Non-EMI Payment Discount &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#oneTimeDiscountModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        className="form-control-sm"
                        type="number"
                        name="OneTimeDiscount"
                        id="one-time-discount"
                        defaultValue={0}
                        min={0}
                        max="10000000"
                        onChange={(e) => {
                          const value = e.target.value;
                          updateDiscount("oneTime", value === "" ? "" : Number(value));
                        }}
                        value={discounts.oneTime}
                      />
                    </fieldset>

                    <fieldset className="border-filter">
                      <legend className="float-none w-auto">
                        EMI Payment Discount &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#emiPaymentDiscountModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        type="number"
                        className="form-control-sm"
                        step="0.01"
                        name="EmiPaymentDiscount"
                        min={0}
                        max="10000000"
                        id="emi-payment-discount"
                        defaultValue={0}
                        onChange={(e) => {
                          const value = e.target.value;
                          updateDiscount("emiPayment", value === "" ? "" : Number(value));
                        }}
                        value={discounts.emiPayment}
                      />
                    </fieldset>

                    <fieldset className="border-filter">
                      <legend className="float-none w-auto">
                        EMI &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#emiModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        type="number"
                        className="form-control-sm"
                        step="0.01"
                        name="EMI"
                        min={0}
                        max="10000000"
                        id="emi"
                        onChange={(e) => {
                          const value = e.target.value;
                          setEMI(value === "" ? "" : Number(value));
                        }}
                        value={EMI}
                      />
                    </fieldset>

                    <fieldset className="border-filter">
                      <legend className="float-none w-auto">
                        No Cost EMI Discount &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#NoCostEMIDiscountModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        type="number"
                        className="form-control-sm"
                        step="0.01"
                        name="NoCostEmiDiscount"
                        min={0}
                        max="10000000"
                        defaultValue={0}
                        id="no-cost-emi-discount"
                        onChange={(e) => {
                          const value = e.target.value;
                          updateDiscount("noCostEmi", value === "" ? "" : Number(value));
                        }}
                        value={discounts.noCostEmi}
                      />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">
                        Tenure in Months &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#tenureModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        required
                        className="form-control-sm"
                        type="number"
                        name="Tenure"
                        id="tenure"
                        min={1}
                        max="1200"
                        onChange={(e) => {
                          const value = e.target.value;
                          setTenure(value === "" ? "" : Number(value));
                        }}
                        value={tenure}
                      />
                    </fieldset>

                    <fieldset className="border-filter ">
                      <legend className="float-none w-auto">
                        Processing Fees &nbsp;{" "}
                        <a
                          href="#"
                          className="info-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#processingFeesModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </legend>
                      <input
                        className="form-control-sm"
                        type="number"
                        id="proc-fees"
                        min={0}
                        max="10000000"
                        step="0.01"
                        defaultValue={0}
                        name="ProcFees"
                        onChange={(e) => {
                          const value = e.target.value;
                          setProcFees(value === "" ? "" : Number(value));
                        }}
                        value={procFees}
                      />
                    </fieldset>
                  </div>
                  <p className="text-center">
                    <button
                      className="btn btn-submit mx-auto m-3"
                      onClick={preSubmit}
                    >
                      Calculate
                    </button>
                    <button style={{
                      display: "none"
                    }}
                      type="submit"
                      id="nce-form-submit"
                    ></button>
                  </p>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-1"></div>
        </div>

        {/* <div className="row aff mt-3">
                    <div className="col-md-1"></div>
                    <div className="col-12 col-md-10 ">
                        <a href="https://amzn.to/3PJNh3s" target="_blank">
                            <img id="affliate-link" src={amazon_aff} alt="Amazon Great Indian Festival Sale" />
                        </a>
                    </div>
                    <div className="col-md-1"></div>
                </div> */}

        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-12 col-md-10 ">
            {tableShow ? (
              <>
                <div className="row mt-3">
                  {calculatedIRR < 0 ? (
                    <p>
                      <b className="text-yellow">
                        You should opt for the EMI option{" "}
                      </b>{" "}
                      as you will have to pay only
                      <span className="text-pastel-green font-size-larger">
                        {" "}
                        Rs. {AmountToBePaid.toFixed(2)}{" "}
                      </span>
                      inclusive of all charges over a period of {tenure} months.
                      If you opt for Non-EMI transaction you will have to pay
                      more i.e.{" "}
                      <span className="text-pastel-green font-size-larger">
                        {" "}
                        Rs. {priceOfTheItem -
                          discounts.oneTime -
                          discounts.common}{" "}
                      </span>{" "}
                      and that too upfront.
                      <br /> <br />
                      Here's the break up of your payments overtime :-
                    </p>
                  ) : (
                    <p>
                      Considering you could have bought the item at
                      <span className="text-pastel-green font-size-larger">
                        {" "}
                        Rs {otpPrice}
                      </span>{" "}
                      <sup>
                        <a
                          href="#"
                          className="info-icon"
                          id="how-info"
                          data-bs-toggle="modal"
                          data-bs-target="#otpPriceInfoModal"
                        >
                          <i className="fa fa-info-circle"></i>
                        </a>
                      </sup>
                      {discounts.noCostEmi > 0 ? (
                        <>
                          , you are actually paying a net financing cost of &nbsp;
                        </>
                      ) : (
                        <>
                          , you are actually paying a financing cost of &nbsp;
                        </>
                      )}
                      <b className="text-yellow font-size-xlarge">
                        {(calculatedIRR * 100).toFixed(2)}% per annum
                      </b>
                      {
                        discounts.noCostEmi > 0 &&
                        <> for this "No Cost EMI"</>
                      }
                      . Even though your EMI is supposed to be{" "}
                      <span className="text-pastel-green font-size-larger">
                        {" "}
                        Rs {actualEmi.toFixed(2)}
                      </span>
                      , you will actually be paying{" "}
                      <span className="text-pastel-green font-size-larger">
                        {" "}
                        Rs {AmountToBePaid.toFixed(2)}
                      </span>{" "}
                      in total over a period of{" "}
                      <span className="text-pastel-green font-size-larger">
                        {tenure} months
                      </span>
                      .
                      <br />
                      <br />
                      Here's the break up of your payments overtime :-
                    </p>
                  )}
                </div>
              </>
            ) : (
              <div></div>
            )}
          </div>

          <div className="col-md-1"></div>
        </div>

        {/* <div className="row aff mt-3">
                    <div className="col-md-1"></div>
                    <div className="col-12 col-md-10 ">
                        <a href="http://fkrt.it/r0K7SENNNN" className="mt-5" target="_blank">
                            <img id="affliate-link" src={flipkart_aff} alt="Flipkart The Big Billion Days Sale" />
                        </a>
                    </div>
                    <div className="col-md-1"></div>
                </div> */}

        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-12 col-md-10 ">
            {tableShow ? (
              <>
                <div className="amortisation_table mt-3">
                  {/* We need month, current loan amount, principle repiad, gst on interest */}

                  <table className="table sortable">
                    <thead>
                      <tr>
                        <th key="month">Month</th>
                        <th key="beginning_bal">Remaining Balance</th>
                        <th key="principle_repaid">Principle Repaid</th>
                        <th key="interest_paid">Interest Paid</th>
                        <th key="gst_on_interest">GST on Interest</th>
                        <th key="proc_fees">Processing Fees + GST</th>
                        <th key="total_monthly_payment">
                          Total Monthly Payment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item) => {
                        return (
                          <tr key={item.month}>
                            <td>{item.month}</td>
                            <td>{item.current_loan_amount}</td>
                            <td>{item.principle_repaid}</td>
                            <td>{item.interest_paid}</td>
                            <td>{item.gst_on_interest}</td>
                            <td>{item.proc_fees}</td>
                            <td>{item.total_monthly_payment}</td>
                          </tr>
                        );
                      })}
                      <tr id="total-row">
                        <td></td>
                        <td>Total</td>
                        <td>{netPrincipalRepaid}</td>
                        <td>{netInterestPaid}</td>
                        <td>{netGstOnInterest}</td>
                        <td>{netProcFeesGst}</td>
                        <td>{AmountToBePaid.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row mt-3">
                  <p className="text-yellow">
                    Total hidden charges: Rs. {netGstOnInterest} + Rs.{" "}
                    {netProcFeesGst} ={" "}
                    <span className="font-size-larger font-weight-bold">
                      Rs. {hiddenCharges.toFixed(2)}
                    </span>{" "}
                    (
                    {(
                      (Number(hiddenCharges) / Number(priceOfTheItem)) *
                      100
                    ).toFixed(2)}
                    % of the Price)
                  </p>
                </div>
              </>
            ) : (
              <div></div>
            )}

          </div>
          <div className="col-md-1"></div>
        </div>

        {/* Related Links and Disclaimer */}
        <div className="row">
          <div className="col-md-1"></div>

          <div className="col-12 col-md-10 ">
            <div className="row mt-3">
              <p className="font-weight-light">
                In case of any query/feedback, please reach out to us using the{" "}
                <Link
                  to="/contact-us"
                  className="link-page"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Contact Us
                </Link>{" "}
                section.
              </p>
            </div>

            <div className="row my-4">
              <h5>Related Links:</h5>
              <ul className="list-group">
                <li className="list-group-item bg-transparent">
                  <a
                    className="link-page"
                    href="https://economictimes.indiatimes.com/wealth/borrow/what-is-the-actual-cost-of-no-cost-emi-you-pay/articleshow/66213515.cms"
                  >
                    What is the actual cost of 'No-cost EMI' you pay?{" "}
                    <i>- By Economic Times</i>
                  </a>
                </li>
                <li className="list-group-item bg-transparent">
                  <a
                    className="link-page"
                    href="https://www.financialexpress.com/money/smart-shopping-no-cost-emi-has-hidden-costs/2702565/"
                  >
                    No-Cost EMI Scheme: Should you opt for it? Here's what you
                    must know <i>- By Financial Express</i>
                  </a>
                </li>
                <li className="list-group-item bg-transparent">
                  <a
                    className="link-page"
                    href="https://www.financialexpress.com/money/smart-shopping-no-cost-emi-has-hidden-costs/2702565/"
                  >
                    What Is No-Cost EMI And How Does It Work{" "}
                    <i>- By Forbes ADVISOR</i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="row">
              <small className="mb-3 desclaimer">
                <strong>Disclaimer:</strong>
                <i>
                  <br />
                  This tool works only for Indian Credit Card No Cost EMI loans
                  considering GST rate of 18%. Please do not use this for other
                  types of loan calculations.
                </i>{" "}
                <br />
              </small>
            </div>
            <div>
              <Comments articleId={"no_cost_emi"} />
            </div>

            {/* Amazon Banner */}
            <div className="my-4">
              <Banner />
            </div>
          </div>

          <div className="col-md-1"></div>
        </div>
      </div>

      {/* MODALS FOR INFO BUTTONS ON THE LABELS OF INPUT TAGS */}

      {/* FOR PRICE INFO */}
      <div
        className="modal fade"
        id="priceModal"
        tabIndex="-1"
        aria-labelledby="priceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="priceModalLabel">
                Price
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-auto">
              <p>Listed (Selling) Price of the item.</p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={priceOfTheItem_info}
                  alt="Example of where to find Price"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* COUPON DISCOUNT INFO */}
      <div
        className="modal fade"
        id="couponDiscountModal"
        tabIndex="-1"
        aria-labelledby="couponDiscountModal"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="couponDiscountModalLabel">
                Coupon Discount
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-auto">
              <p>
                If there is any coupon discount available enter it here. Enter
                zero if not applicable.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={CouponDiscount_info}
                  alt="Example of where to find Price"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* COMMON DISCOUNT INFO */}
      <div
        className="modal fade"
        id="commonDiscountModal"
        tabIndex="-1"
        aria-labelledby="commonDiscountModal"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="commonDiscountModalLabel">
                Common Discount
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-auto">
              <p>
                This discount is applicable on both EMI and Non-EMI
                transactions. Under bank offers for a credit card, when EMI or
                non-EMI transaction is not specified, it is typically applicable
                to both and you should sum all such applicable offers for your
                card and enter it here. Enter zero if not applicable.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={CommonDiscount_info}
                  alt="Example of where to find Price"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ONE TIME DISCOUNT */}
      <div
        className="modal fade"
        id="oneTimeDiscountModal"
        tabIndex="-1"
        aria-labelledby="oneTimeDiscountModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="oneTimeDiscountModalLabel">
                Non-EMI Only Payment Discount
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-auto">
              <p>
                This discount is applicable only on Non-EMI transactions. This
                is typically clearly specified in the bank offer detail and you
                should sum all such applicable offers for your card and enter it
                here. Enter zero if not applicable.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={OneTimePaymentDiscount_info}
                  alt="Example of where to find One Time Discount"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EMI PAYMENT DISCOUNT INFO */}
      <div
        className="modal fade"
        id="emiPaymentDiscountModal"
        tabIndex="-1"
        aria-labelledby="emiPaymentDiscountModal"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="emiPaymentDiscountModalLabel">
                EMI Payment Discount
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-auto">
              <p>
                This discount is applicable only on EMI transactions. This is
                typically clearly specified in the bank offer detail and you
                should sum all such applicable offers for your card and enter it
                here. Enter zero if not applicable.
                <br />
                <br />
                Note - Do not consider the No Cost EMI discount here. Click on
                the i-button next to No Cost EMI discount text box for more
                details.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={EmiPaymentDiscount_info}
                  alt="Example of where to find Price"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EMI INFO */}
      <div
        className="modal fade"
        id="emiModal"
        tabIndex="-1"
        aria-labelledby="emiModal"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="emiModalLabel">
                EMI
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-auto">
              <p>
                Enter the applicable EMI according to your desired tenure.
                <br /> <br />
                Note -{" "}
                <b>Please enter EMI as shown on the product page only. </b> This
                number might change on the payment page while checking out, but
                we’ll already account for that from all the other information
                your provide us.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img src={EMI_info} alt="Example of where to find Price" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* NO COST EMI DISCOUNT */}
      <div
        className="modal fade"
        id="NoCostEMIDiscountModal"
        tabIndex="-1"
        aria-labelledby="NoCostEMIDiscountModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="NoCostEMIDiscountModalLabel">
                No Cost EMI Discount
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-auto">
              <p>
                <b>
                  Applicable only for No Cost EMIs, please enter zero for
                  standard EMI plans.
                </b>{" "}
                <br />
                <br />
                This is the interest amount that is being given as a discount to
                you to facilitate the "no-cost" EMI. (Essentially the interest
                that was supposed to be charged but is being passed on to you as
                an upfront discount)
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={NoCostEMIDiscount_info}
                  alt="Example of where to find No Cost EMI Discount"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TENURE */}
      <div
        className="modal fade"
        id="tenureModal"
        tabIndex="-1"
        aria-labelledby="tenureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="tenureModalLabel">
                Tenure
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-auto">
              <p>
                Number of months for which you are opting for the no cost emi
                option.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img src={tenure_info} alt="Example of where to find Tenure" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PROCESSING FEES */}
      <div
        className="modal fade"
        id="processingFeesModal"
        tabIndex="-1"
        aria-labelledby="processingFeesModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="processingFeesModalLabel">
                Processing Fees
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-auto">
              <p>
                Processing Fees being charged from your card to facilitate the
                NCE transaction.
              </p>
              <div className="row mx-auto m-5">
                <p className="p-2">Example:</p>
                <img
                  src={procFees_info}
                  alt="Example of where to find Processing Fees"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* HOW DIALOG BOX */}
      <div
        className="modal fade"
        id="otpPriceInfoModal"
        tabIndex="-1"
        aria-labelledby="otpPriceInfoModal"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content mt-5 mx-auto text-black">
            <div className="modal-header">
              <h5 className="modal-title" id="otpPriceInfoModalLabel">
                How?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-0">
              <table>
                <tbody>
                  <tr>
                    <td>Price</td>
                    <td>Rs. {priceOfTheItem}</td>
                  </tr>
                  <tr>
                    <td>Common Discount</td>
                    <td> - Rs. {discounts.common}</td>
                  </tr>
                  <tr>
                    <td>One-Time Discount</td>
                    <td>- Rs. {discounts.oneTime}</td>
                  </tr>
                  <tr>
                    <td>Coupon Discount</td>
                    <td>- Rs. {discounts.coupon}</td>
                  </tr>
                  <hr />
                  <tr>
                    <td>Net Price (One Time Payment)</td>
                    <td>Rs. {otpPrice}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewNoCostEMI;