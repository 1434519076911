import { FaUserCircle } from "react-icons/fa";
import styled from "styled-components";

export default function UserAvatar(props) {
    const { photoUrl } = props;

    if (!!photoUrl) {
        return <AvatarImage src={photoUrl} alt="profile" />
    }

    return <AnonymousUser />
}

const avatarCss = `
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-basis: 40px;
	flex-grow: 0;
	flex-shrink: 0;
`

const AvatarImage = styled.img`
    ${avatarCss}
`
const AnonymousUser = styled(FaUserCircle)`    
    ${avatarCss}
    display: grid;
    place-items: center;
`